import {
  checkinViewRequest,
  checkinStaffViewRequest,
  checkinServiceRequest
} from 'utils/ManagementEndpointRequest/TimelineSocket'
import {
  ParamsGetDetailTimekeeping,
  ParamsGetListTimekeeping,
  ParamsHourTimekeeping,
  PayloadCopyTimekeeping,
  PayloadCreateTimekeeping,
  PayloadDeleteTimekeeping,
  ParamsGetListErrorDays
} from './type'
import { listMenu } from 'utils/requestManager/Author'

export const getListDepartment = () => {
  return checkinViewRequest.get('/flow-timekeeping-view/get-list-staff-type')
}

export const getListStaffBySalon = (salonId: number) => {
  return checkinStaffViewRequest.get(`/api/timekeeping/list-staff?salonId=${salonId}`)
}
export const getListWorkTime = () => {
  return checkinViewRequest.get('/flow-timekeeping-view/get-list-worktime')
}
export const copyTimekeeping = (payload: PayloadCopyTimekeeping) => {
  return checkinServiceRequest.post('/flow-timekeeping/copy-time-keeping', payload)
}
export const getListHourTimekeeping = (params: ParamsHourTimekeeping) => {
  return checkinViewRequest.get('/flow-timekeeping-view/get-list-hour-timekeeping', { params })
}
export const createTimeKeeping = (payload: PayloadCreateTimekeeping) => {
  return checkinServiceRequest.post('/flow-timekeeping/push-staff-to-create-timekeeping', payload)
}
export const updateTimeKeeping = (payload: PayloadCreateTimekeeping) => {
  return checkinServiceRequest.put('/flow-timekeeping/push-staff-to-update-timekeeping', payload)
}
export const deleteTimeKeeping = (payload: PayloadDeleteTimekeeping) => {
  return checkinServiceRequest.delete('/flow-timekeeping/push-staff-to-delete-timekeeping', { data: payload })
}
export const getListTimeKeeping = (params: ParamsGetListTimekeeping) => {
  return checkinServiceRequest.get('/flow-timekeeping/get-list-timekeeping', {
    params
  })
}
export const getDetailTimeKeeping = (params: ParamsGetDetailTimekeeping) => {
  return checkinServiceRequest.get('/flow-timekeeping/get-timekeeping-detail', {
    params
  })
}
export const getLogTimeKeeping = (streamId: string) => {
  return checkinViewRequest.get(`/event-log-timekeeping/get-eventlog-timekeeping?streamId=${streamId}`)
}
export const getAllSalon = () => {
  return checkinViewRequest.get(`/flow-timekeeping-view/get-list-salon`)
}
export const getActionTimekeeping = (url: string) => {
  return listMenu.get(`/api/menu-action?path=${url}`)
}
export const getListErrorDays = (params: ParamsGetListErrorDays) => {
  return checkinServiceRequest.get('/flow-timekeeping/error-days', {
    params
  })
}
